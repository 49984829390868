body, html {
  margin: 0;
  padding: 0;
}

body {
  background-color: black;
}


h1 {
  margin: 0;
}

.App {
  position: relative;
}


.sectionCont {
  background-color: #000000;
  color: white;
  min-height: 600px;
  position: relative;
  //-webkit-transform: translateZ(0);

  .content {
    padding: 1em;
  }

  .navigationCont {
    display: inline-block;
    position: absolute;
    right: 0;


    .dot {
      top: 150px;
      display: flex;
      align-items: center;


      .icon {
        width: 50px;
        height: 50px;
        background-color: aquamarine;
      }

      .caption {

      }
    }

  }
}


.navCont {
  width: 100%;
  height: 100%;
  top: 0;
  background-color: #22ffaa;
  position: absolute;
  opacity: .2;
}

header {
  position: relative;

  .headerCont {
    color: #ffffff;
    background-color: #333333;
    width: 100%;
    height: 100vh;
  }
}

.startDot {
  width: 50px;
  height: 50px;
  background-color: aquamarine;
  position: fixed;
  top: calc(65vh - 50px)
}


.navigationSide {
  background-color: rgba(255, 255, 255, .2);
  opacity: .8;
  width: 20%;
  height: 100%;
  right: 0;
  position: absolute;
  top: 0;

  overflow: hidden;

  .line {
    position: absolute;
    top: 0;
    width: 2px;
    height: 100%;
    left: 23px;
    background-color: #0f5ef2;
  }


  .navigationItem {
    display: flex;
    align-items: center;
    padding-top: 1em;
    padding-bottom: 1em;
    cursor: pointer;
    z-index: 2;
    position: relative;

    &.sub {
      font-size: .8em;
      position: relative;

      .icon {
        width: 32px;
        height: 32px;
        margin-left: 8px;
        z-index: 2;
        position: relative;
      }

    }

    .icon {
      width: 48px;
      height: 48px;
      background-color: #202020;
      border: 2px solid #0f5ef2;
      border-radius: 50%;
      z-index: 2;
    }

    .caption {
      margin-left: -18px;
      background-color: black;
      white-space: nowrap;
      overflow: hidden;
      border-radius: 25px;
      padding: 5px 25px;
      position: relative;

      .fill {
        position: absolute;
        width: 0;
        height: 100%;
        background-color: #0f5ef2;
        top: 0;
        left: 0;
      }

      .text {
        position: relative;
      }
    }
  }
}


.startTour {
  position: fixed;
  top: 65vh;
  right: calc(20% + 8px);


  .dot {
    position: relative;
    margin-top: -100%;
    margin-left: 100%;
    width: 64px;
    height: 64px;
    background-color: #ff00aa;
    border-radius: 50%;
    cursor: pointer;
  }
}


//Helper
.inViewLine {
  position: fixed;
  width: 100%;
  top: 200px;
  height: 2px;
  background-color: aqua;
  left: 0;
}